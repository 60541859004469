'use client';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { MarkdownRenderer } from 'components/ui/MarkdownRenderer';

import { LocalizationKey } from 'constants/i18n.messages';

import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { TextTransformMap, transformText } from 'utils/transformText';

const LocalizedMessageTooltip = dynamic(
  () => import('components/i18n/LocalizedMessageTooltip/LocalizedMessageTooltip'),
);

type CommonProps = {
  id?: `${LocalizationKey}`;
  values?: Record<string, ReactNode | ((parts: ReactNode) => ReactNode)>;
  defaultMessage?: string;
  withParagraphs?: boolean;
  children?: (content: string) => React.ReactNode | null;
} & TextTransformMap;

interface NormalProps extends CommonProps {
  /**
   * @deprecated We no longer user separate translations for plural instead we use
   * the standard pluralisation format as outlined here
   * https://formatjs.io/docs/intl-messageformat/.
   */
  pluralId?: undefined;
  count?: undefined;
  plural?: undefined;
}

interface WithPlural extends CommonProps {
  plural: boolean;
  /**
   * Use this translation key if plural is true
   *
   * @deprecated We no longer user separate translations for plural instead we use
   * the standard pluralisation format as outlined here
   * https://formatjs.io/docs/intl-messageformat/.
   */
  pluralId: LocalizationKey;
}

interface WithCount extends CommonProps {
  count: number;
  /**
   * Use this translation key if count is defined and it's not 1
   *
   * @deprecated We no longer user separate translations for plural instead we use
   * the standard pluralisation format as outlined here
   * https://formatjs.io/docs/intl-messageformat/.
   */
  pluralId?: LocalizationKey;
}

type Props = NormalProps | WithPlural | WithCount;

const getMessage = (
  {
    id,
    values,
    defaultMessage,
    uppercase,
    lowercase,
    capitalize,
    withParagraphs,
    children,
  }: CommonProps,
  formatMessage: IntlShape['formatMessage'],
) => {
  const formattedMessage = formatMessage({ id, defaultMessage }, values);

  if (typeof formattedMessage !== 'string') {
    return formattedMessage;
  }

  const transformedText = transformText(formattedMessage, { uppercase, lowercase, capitalize });

  if (children) {
    return children(transformedText);
  }

  return <MarkdownRenderer withParagraphs={withParagraphs}>{transformedText}</MarkdownRenderer>;
};

export const LocalizedMessage = (props: Props) => {
  const [localizationTooltipsEnabled] = useStoredState(StorageKey.LocalizationTooltips);

  const { formatMessage } = useIntl();

  if (!props.id) {
    return null;
  }

  // zero is treated as plural
  // 0 is also used with zero
  // 1 auto, 0 autoa, 20 autoa
  // 1 car, 10 cars, 0 cars
  const resolvedId =
    props.pluralId &&
    (('plural' in props && props.plural) || ('count' in props && Math.abs(props.count) !== 1))
      ? props.pluralId
      : props.id;

  const message = getMessage(
    {
      ...props,
      id: resolvedId,
      ...('count' in props && { values: { count: props.count, ...props.values } }),
    },
    formatMessage,
  );

  if (localizationTooltipsEnabled) {
    return <LocalizedMessageTooltip tooltipContent={resolvedId}>{message}</LocalizedMessageTooltip>;
  }

  return <>{message}</>;
};
