'use client';
/* eslint-disable */
import FrameworkLink from 'next/link';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { getDisabledProps } from '@vrfi/web-components';

import LocalizedMessage from 'components/i18n/LocalizedMessage';

import { AccessibilityMessage } from 'constants/i18n.messages';

import { localizePathname } from 'utils/route';

import { ExternalLinkIcon } from '../Icons';
import ScreenreaderText from '../ScreenreaderText';
import styles from './Link.module.css';

export interface LinkProps extends Omit<ComponentPropsWithoutRef<'a'>, 'href'> {
  'aria-disabled'?: never;
  disabled?: boolean;
  href?: string;
  /** @deprecated use href prop */
  to?: string;
  replace?: boolean;
  scroll?: boolean;
  disableLocalization?: boolean;
  forceInternal?: boolean;
  hideExternalLinkIcon?: boolean;
}

/**
 * Manages internal (framework Link), internal (in-page hash), and external link logic.
 *
 * Color can be set with `--link-color` CSS custom property. This is usually done automatically by Box component.
 *
 * ### Usage
 * - `<Link href="/page" />` (internal using framework's Link)
 * - `<Link href="#content" />` (in-page)
 * - `<Link href="https://vr.fi" />` (external)
 * - `<Link target="_blank" href="/page" />` (enforced as external)
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  {
    disabled,
    to,
    href,
    target,
    replace,
    scroll,
    disableLocalization,
    forceInternal,
    hideExternalLinkIcon,
    rel,
    ...props
  },
  ref,
) {
  const { locale } = useIntl();
  href = href || to;
  href = href && !disableLocalization ? localizePathname(locale, href) : href;

  // Links with target _blank must be treated as external for the link href open in a new tab

  const isExternal = forceInternal ? false : /^http/.test(String(href)) || target === '_blank';
  const finalTarget = target ?? isExternal ? '_blank' : undefined;
  const isInPage = href?.charAt(0) === '#';
  const isAnchor = href == null || isInPage || isExternal || disabled;

  const anchorProps = { ...props, ...getDisabledProps(disabled) };

  if (isAnchor || isExternal || !href) {
    return (
      <a
        {...anchorProps}
        href={disabled ? undefined : href} // links cannot be disabled
        target={finalTarget}
        rel={rel ?? finalTarget === '_blank' ? 'noopener noreferrer' : undefined}
        ref={ref}
      >
        {props.children}
        {isExternal && !hideExternalLinkIcon && (
          <>
            <span className={styles.externalLinkIcon}>
              &#xFEFF;
              <ExternalLinkIcon size={1.25} />
            </span>

            <ScreenreaderText>
              , <LocalizedMessage id={AccessibilityMessage.OPENS_IN_NEW_TAB} />
            </ScreenreaderText>
          </>
        )}
      </a>
    );
  }

  return <FrameworkLink href={href} replace={replace} scroll={scroll} {...anchorProps} ref={ref} />;
});
