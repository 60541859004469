import { entries } from './object';
import { capitalize, toLowerCase, toUpperCase } from './string';

const transformFns = {
  uppercase: toUpperCase,
  lowercase: toLowerCase,
  capitalize,
};

type TextTransform = keyof typeof transformFns;
export type TextTransformMap = Partial<Record<TextTransform, boolean>>;

/**
 * example: `transformText('text', { uppercase: true })`
 */
export const transformText = (text: string, transforms: TextTransformMap) => {
  return entries(transforms)
    .filter(([_, enabled]) => enabled)
    .reduce((acc, [transform]) => transformFns[transform](acc), text);
};
