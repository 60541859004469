import { Maybe } from 'types/utils';

export const urlSlug = (text?: string | null) =>
  text
    ? text
        .toLowerCase()
        .replace(/&shy;+/g, '')
        .replace(/å+/g, 'a')
        .replace(/ä+/g, 'a')
        .replace(/ö+/g, 'o')
        .replace(/\W+/g, '-')
    : '';

/** @private export used for testing */
export const toLowerCaseOrEmpty = (text?: string) => (text || '').toLowerCase();

export const capitalize = (text: string) =>
  text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1) : text;

const padLeft =
  (padWith: string) =>
  (chars: number) =>
  (input: number): string =>
    `${input}`.padStart(chars, padWith);

export const zeroPadLeft = padLeft('0');

export const truncate = (text: string, maxLength: number) => {
  if (text.length > maxLength) return text.substring(0, maxLength - 3) + '…';
  return text;
};

export const parseEntities = (text: Maybe<string>) => text?.replace(/&shy;/g, '\u00AD') ?? '';

export const toUpperCase = (text: string) => text.toUpperCase();
export const toLowerCase = (text: string) => text.toLowerCase();

export const nonBreakingSpace = '\u00A0';

export const hash = async (value: string) => {
  const utf8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
};
