import {
  createDetails,
  createIcon,
  createLine,
  createRow,
  createStepNav,
  createText,
  createVerticalRadioSteps,
} from '@vrfi/web-components';

export { Box, Grid } from '@vrfi/web-components';

import details from '@vrfi/web-components/css/details.module.css';
import icon from '@vrfi/web-components/css/icon.module.css';
import line from '@vrfi/web-components/css/line.module.css';
import row from '@vrfi/web-components/css/row.module.css';
import stepNav from '@vrfi/web-components/css/stepNav.module.css';

import typography from '@vrfi/web-components/css/typography.module.css';
import verticalRadioSteps from '@vrfi/web-components/css/verticalRadioSteps.module.css';

import { margin, padding } from 'styles/compLib';

export const Details = createDetails({ details });

export const Icon = createIcon({ icon });
export const Line = createLine({ line, margin, padding });
export const { Col, Row } = createRow({ row });
export const { Step, StepNav } = createStepNav({ stepNav });

export const Text = createText({ typography });

export const { VerticalRadioStep, VerticalRadioSteps } = createVerticalRadioSteps({
  verticalRadioSteps,
});
