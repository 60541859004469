import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { ReactNode, memo } from 'react';

import utils from '@vrfi/web-components/css/utils.module.css';

import { Link } from '../Link/Link';

interface Props {
  children: string;
  /** Trim margin of wrapper */
  withParagraphs?: boolean;
  openLinksInNewTab?: boolean;
  className?: string;
}

export const MarkdownRenderer = memo(function MarkdownRenderer({
  children,
  withParagraphs,
  openLinksInNewTab,
  className,
}: Props) {
  const wrapperClass = classNames(className, { [utils.marginTrimBlock]: withParagraphs });

  return (
    <Markdown
      options={{
        overrides: {
          a: { component: Link, props: openLinksInNewTab ? { target: '_blank' } : undefined },
        },
        disableParsingRawHTML: true,
        forceWrapper: true,

        forceBlock: withParagraphs,
        wrapper: ({ children }: { children: ReactNode }) =>
          wrapperClass ? <div className={wrapperClass}>{children}</div> : <>{children}</>,
      }}
    >
      {children}
    </Markdown>
  );
});
