// Generated file, see /scripts/generate-icon-components.js
import { IconProps } from '@vrfi/web-components';

import { Icon } from 'components/cssModules';

import AVCabin from '@vrfi/web-components/icons/AVCabin.svg?as-file';
import AccessibleLift from '@vrfi/web-components/icons/AccessibleLift.svg?as-file';
import Add from '@vrfi/web-components/icons/Add.svg?as-file';
import AddFilled from '@vrfi/web-components/icons/AddFilled.svg?as-file';
import AddLines from '@vrfi/web-components/icons/AddLines.svg?as-file';
import AirConditioning from '@vrfi/web-components/icons/AirConditioning.svg?as-file';
import Airplane from '@vrfi/web-components/icons/Airplane.svg?as-file';
import Alert from '@vrfi/web-components/icons/Alert.svg?as-file';
import AlertFilled from '@vrfi/web-components/icons/AlertFilled.svg?as-file';
import AlertLines from '@vrfi/web-components/icons/AlertLines.svg?as-file';
import Allegro from '@vrfi/web-components/icons/Allegro.svg?as-file';
import Allergy from '@vrfi/web-components/icons/Allergy.svg?as-file';
import ArrowLeft from '@vrfi/web-components/icons/ArrowLeft.svg?as-file';
import ArrowLongLeft from '@vrfi/web-components/icons/ArrowLongLeft.svg?as-file';
import ArrowLongRight from '@vrfi/web-components/icons/ArrowLongRight.svg?as-file';
import ArrowRight from '@vrfi/web-components/icons/ArrowRight.svg?as-file';
import BabyStation from '@vrfi/web-components/icons/BabyStation.svg?as-file';
import BasicSeat from '@vrfi/web-components/icons/BasicSeat.svg?as-file';
import Bed from '@vrfi/web-components/icons/Bed.svg?as-file';
import Bike from '@vrfi/web-components/icons/Bike.svg?as-file';
import BikeUnavailable from '@vrfi/web-components/icons/BikeUnavailable.svg?as-file';
import Bin from '@vrfi/web-components/icons/Bin.svg?as-file';
import BunkBed from '@vrfi/web-components/icons/BunkBed.svg?as-file';
import Bus from '@vrfi/web-components/icons/Bus.svg?as-file';
import BusStation from '@vrfi/web-components/icons/BusStation.svg?as-file';
import BusinessTraveller from '@vrfi/web-components/icons/BusinessTraveller.svg?as-file';
import Cabin from '@vrfi/web-components/icons/Cabin.svg?as-file';
import CabinCombined from '@vrfi/web-components/icons/CabinCombined.svg?as-file';
import CabinWithWCShower from '@vrfi/web-components/icons/CabinWithWCShower.svg?as-file';
import Calendar from '@vrfi/web-components/icons/Calendar.svg?as-file';
import Cancel from '@vrfi/web-components/icons/Cancel.svg?as-file';
import CancelFilled from '@vrfi/web-components/icons/CancelFilled.svg?as-file';
import Car from '@vrfi/web-components/icons/Car.svg?as-file';
import CarTrain from '@vrfi/web-components/icons/CarTrain.svg?as-file';
import Chain from '@vrfi/web-components/icons/Chain.svg?as-file';
import ChangeWait from '@vrfi/web-components/icons/ChangeWait.svg?as-file';
import Changes from '@vrfi/web-components/icons/Changes.svg?as-file';
import Chat from '@vrfi/web-components/icons/Chat.svg?as-file';
import ChevronDown from '@vrfi/web-components/icons/ChevronDown.svg?as-file';
import ChevronLeft from '@vrfi/web-components/icons/ChevronLeft.svg?as-file';
import ChevronRight from '@vrfi/web-components/icons/ChevronRight.svg?as-file';
import ChevronUp from '@vrfi/web-components/icons/ChevronUp.svg?as-file';
import Cloakroom from '@vrfi/web-components/icons/Cloakroom.svg?as-file';
import Cocktail from '@vrfi/web-components/icons/Cocktail.svg?as-file';
import Coffee from '@vrfi/web-components/icons/Coffee.svg?as-file';
import Collapse from '@vrfi/web-components/icons/Collapse.svg?as-file';
import Conductor from '@vrfi/web-components/icons/Conductor.svg?as-file';
import ConnectedCabin from '@vrfi/web-components/icons/ConnectedCabin.svg?as-file';
import CreditCard from '@vrfi/web-components/icons/CreditCard.svg?as-file';
import DetailedGlacier from '@vrfi/web-components/icons/DetailedGlacier.svg?as-file';
import DetailedHamburger from '@vrfi/web-components/icons/DetailedHamburger.svg?as-file';
import Direct from '@vrfi/web-components/icons/Direct.svg?as-file';
import DisabledServices from '@vrfi/web-components/icons/DisabledServices.svg?as-file';
import Download from '@vrfi/web-components/icons/Download.svg?as-file';
import Duration from '@vrfi/web-components/icons/Duration.svg?as-file';
import Edit from '@vrfi/web-components/icons/Edit.svg?as-file';
import Eko from '@vrfi/web-components/icons/Eko.svg?as-file';
import Ekstra from '@vrfi/web-components/icons/Ekstra.svg?as-file';
import EkstraNewEn from '@vrfi/web-components/icons/EkstraNewEn.svg?as-file';
import EkstraNewFi from '@vrfi/web-components/icons/EkstraNewFi.svg?as-file';
import EkstraNewSe from '@vrfi/web-components/icons/EkstraNewSe.svg?as-file';
import Email from '@vrfi/web-components/icons/Email.svg?as-file';
import EmotionHappy from '@vrfi/web-components/icons/EmotionHappy.svg?as-file';
import EmotionSad from '@vrfi/web-components/icons/EmotionSad.svg?as-file';
import Expand from '@vrfi/web-components/icons/Expand.svg?as-file';
import ExternalLink from '@vrfi/web-components/icons/ExternalLink.svg?as-file';
import FaceForward from '@vrfi/web-components/icons/FaceForward.svg?as-file';
import Facebook from '@vrfi/web-components/icons/Facebook.svg?as-file';
import FacingSeats from '@vrfi/web-components/icons/FacingSeats.svg?as-file';
import Family from '@vrfi/web-components/icons/Family.svg?as-file';
import Ferry from '@vrfi/web-components/icons/Ferry.svg?as-file';
import File from '@vrfi/web-components/icons/File.svg?as-file';
import FullScreen from '@vrfi/web-components/icons/FullScreen.svg?as-file';
import HearingAid from '@vrfi/web-components/icons/HearingAid.svg?as-file';
import Heart from '@vrfi/web-components/icons/Heart.svg?as-file';
import Home from '@vrfi/web-components/icons/Home.svg?as-file';
import InfoCircleFilled from '@vrfi/web-components/icons/InfoCircleFilled.svg?as-file';
import InfoCircleLines from '@vrfi/web-components/icons/InfoCircleLines.svg?as-file';
import Instagram from '@vrfi/web-components/icons/Instagram.svg?as-file';
import Laptop from '@vrfi/web-components/icons/Laptop.svg?as-file';
import LargeLuggage from '@vrfi/web-components/icons/LargeLuggage.svg?as-file';
import LocalTraffic from '@vrfi/web-components/icons/LocalTraffic.svg?as-file';
import Location from '@vrfi/web-components/icons/Location.svg?as-file';
import Lock from '@vrfi/web-components/icons/Lock.svg?as-file';
import Luggage from '@vrfi/web-components/icons/Luggage.svg?as-file';
import LuggageStorage from '@vrfi/web-components/icons/LuggageStorage.svg?as-file';
import Man from '@vrfi/web-components/icons/Man.svg?as-file';
import ManWoman from '@vrfi/web-components/icons/ManWoman.svg?as-file';
import Meal from '@vrfi/web-components/icons/Meal.svg?as-file';
import Metro from '@vrfi/web-components/icons/Metro.svg?as-file';
import MiniBistroTrolley from '@vrfi/web-components/icons/MiniBistroTrolley.svg?as-file';
import MotorBike from '@vrfi/web-components/icons/MotorBike.svg?as-file';
import Motorcycle from '@vrfi/web-components/icons/Motorcycle.svg?as-file';
import NegotiationCabin from '@vrfi/web-components/icons/NegotiationCabin.svg?as-file';
import NightTrain from '@vrfi/web-components/icons/NightTrain.svg?as-file';
import NoStairs from '@vrfi/web-components/icons/NoStairs.svg?as-file';
import NoWifi from '@vrfi/web-components/icons/NoWifi.svg?as-file';
import Notifications from '@vrfi/web-components/icons/Notifications.svg?as-file';
import Offer from '@vrfi/web-components/icons/Offer.svg?as-file';
import OfferOutline from '@vrfi/web-components/icons/OfferOutline.svg?as-file';
import Parking from '@vrfi/web-components/icons/Parking.svg?as-file';
import PasswordHide from '@vrfi/web-components/icons/PasswordHide.svg?as-file';
import PasswordView from '@vrfi/web-components/icons/PasswordView.svg?as-file';
import Pet from '@vrfi/web-components/icons/Pet.svg?as-file';
import PetCabin from '@vrfi/web-components/icons/PetCabin.svg?as-file';
import PetCabinWithShowerWC from '@vrfi/web-components/icons/PetCabinWithShowerWC.svg?as-file';
import PetCabinWithWC from '@vrfi/web-components/icons/PetCabinWithWC.svg?as-file';
import PetCabinWithWCShower from '@vrfi/web-components/icons/PetCabinWithWCShower.svg?as-file';
import PetUnavailable from '@vrfi/web-components/icons/PetUnavailable.svg?as-file';
import PhoneBooth from '@vrfi/web-components/icons/PhoneBooth.svg?as-file';
import PlayArea from '@vrfi/web-components/icons/PlayArea.svg?as-file';
import Plug from '@vrfi/web-components/icons/Plug.svg?as-file';
import Pram from '@vrfi/web-components/icons/Pram.svg?as-file';
import Print from '@vrfi/web-components/icons/Print.svg?as-file';
import QR from '@vrfi/web-components/icons/QR.svg?as-file';
import QuestionMark from '@vrfi/web-components/icons/QuestionMark.svg?as-file';
import QuestionMarkFilled from '@vrfi/web-components/icons/QuestionMarkFilled.svg?as-file';
import Receipt from '@vrfi/web-components/icons/Receipt.svg?as-file';
import Reload from '@vrfi/web-components/icons/Reload.svg?as-file';
import ReloadVariant from '@vrfi/web-components/icons/ReloadVariant.svg?as-file';
import Remove from '@vrfi/web-components/icons/Remove.svg?as-file';
import RemoveFilled from '@vrfi/web-components/icons/RemoveFilled.svg?as-file';
import RemoveLines from '@vrfi/web-components/icons/RemoveLines.svg?as-file';
import Restaurant from '@vrfi/web-components/icons/Restaurant.svg?as-file';
import RestaurantTrolley from '@vrfi/web-components/icons/RestaurantTrolley.svg?as-file';
import RestaurantUpstairs from '@vrfi/web-components/icons/RestaurantUpstairs.svg?as-file';
import Retail from '@vrfi/web-components/icons/Retail.svg?as-file';
import Return from '@vrfi/web-components/icons/Return.svg?as-file';
import Scale from '@vrfi/web-components/icons/Scale.svg?as-file';
import Search from '@vrfi/web-components/icons/Search.svg?as-file';
import Send from '@vrfi/web-components/icons/Send.svg?as-file';
import Settings from '@vrfi/web-components/icons/Settings.svg?as-file';
import Share from '@vrfi/web-components/icons/Share.svg?as-file';
import Shower from '@vrfi/web-components/icons/Shower.svg?as-file';
import Silent from '@vrfi/web-components/icons/Silent.svg?as-file';
import Sorting from '@vrfi/web-components/icons/Sorting.svg?as-file';
import Station from '@vrfi/web-components/icons/Station.svg?as-file';
import Swap from '@vrfi/web-components/icons/Swap.svg?as-file';
import Sweet from '@vrfi/web-components/icons/Sweet.svg?as-file';
import TVM from '@vrfi/web-components/icons/TVM.svg?as-file';
import Taxi from '@vrfi/web-components/icons/Taxi.svg?as-file';
import TaxiStop from '@vrfi/web-components/icons/TaxiStop.svg?as-file';
import ThumbsDown from '@vrfi/web-components/icons/ThumbsDown.svg?as-file';
import ThumbsUp from '@vrfi/web-components/icons/ThumbsUp.svg?as-file';
import Tick from '@vrfi/web-components/icons/Tick.svg?as-file';
import TickFilled from '@vrfi/web-components/icons/TickFilled.svg?as-file';
import Ticket from '@vrfi/web-components/icons/Ticket.svg?as-file';
import TicketSeason from '@vrfi/web-components/icons/TicketSeason.svg?as-file';
import TicketSerial from '@vrfi/web-components/icons/TicketSerial.svg?as-file';
import TicketSingle from '@vrfi/web-components/icons/TicketSingle.svg?as-file';
import TimeDetailed from '@vrfi/web-components/icons/TimeDetailed.svg?as-file';
import TimeSimple from '@vrfi/web-components/icons/TimeSimple.svg?as-file';
import Track from '@vrfi/web-components/icons/Track.svg?as-file';
import TrainCommuter from '@vrfi/web-components/icons/TrainCommuter.svg?as-file';
import TrainDefault from '@vrfi/web-components/icons/TrainDefault.svg?as-file';
import TrainDetailed from '@vrfi/web-components/icons/TrainDetailed.svg?as-file';
import TrainInfo from '@vrfi/web-components/icons/TrainInfo.svg?as-file';
import TrainInterCity from '@vrfi/web-components/icons/TrainInterCity.svg?as-file';
import TrainLoadLevel from '@vrfi/web-components/icons/TrainLoadLevel.svg?as-file';
import TrainLoadSpaceLimited from '@vrfi/web-components/icons/TrainLoadSpaceLimited.svg?as-file';
import TrainLoadSpaceModerate from '@vrfi/web-components/icons/TrainLoadSpaceModerate.svg?as-file';
import TrainLoadSpacePlenty from '@vrfi/web-components/icons/TrainLoadSpacePlenty.svg?as-file';
import TrainLongDistance from '@vrfi/web-components/icons/TrainLongDistance.svg?as-file';
import TrainPendolino from '@vrfi/web-components/icons/TrainPendolino.svg?as-file';
import TrainPlus from '@vrfi/web-components/icons/TrainPlus.svg?as-file';
import TrainShortDistance from '@vrfi/web-components/icons/TrainShortDistance.svg?as-file';
import TrainStation from '@vrfi/web-components/icons/TrainStation.svg?as-file';
import Tram from '@vrfi/web-components/icons/Tram.svg?as-file';
import Travel from '@vrfi/web-components/icons/Travel.svg?as-file';
import Twitter from '@vrfi/web-components/icons/Twitter.svg?as-file';
import TwitterX from '@vrfi/web-components/icons/TwitterX.svg?as-file';
import Typography from '@vrfi/web-components/icons/Typography.svg?as-file';
import Umbrella from '@vrfi/web-components/icons/Umbrella.svg?as-file';
import UserDefault from '@vrfi/web-components/icons/UserDefault.svg?as-file';
import UserGroup from '@vrfi/web-components/icons/UserGroup.svg?as-file';
import UserInCircle from '@vrfi/web-components/icons/UserInCircle.svg?as-file';
import UserIndicatorFilled from '@vrfi/web-components/icons/UserIndicatorFilled.svg?as-file';
import UserIndicatorOutline from '@vrfi/web-components/icons/UserIndicatorOutline.svg?as-file';
import VRLogo from '@vrfi/web-components/icons/VRLogo.svg?as-file';
import Van from '@vrfi/web-components/icons/Van.svg?as-file';
import WC from '@vrfi/web-components/icons/WC.svg?as-file';
import WCAccessible from '@vrfi/web-components/icons/WCAccessible.svg?as-file';
import Wagon from '@vrfi/web-components/icons/Wagon.svg?as-file';
import WaterBottle from '@vrfi/web-components/icons/WaterBottle.svg?as-file';
import Wheelchair from '@vrfi/web-components/icons/Wheelchair.svg?as-file';
import WheelchairUnavailable from '@vrfi/web-components/icons/WheelchairUnavailable.svg?as-file';
import Wifi from '@vrfi/web-components/icons/Wifi.svg?as-file';
import Wine from '@vrfi/web-components/icons/Wine.svg?as-file';
import Woman from '@vrfi/web-components/icons/Woman.svg?as-file';
import WorkCabin from '@vrfi/web-components/icons/WorkCabin.svg?as-file';
import Youtube from '@vrfi/web-components/icons/Youtube.svg?as-file';
export const AVCabinIcon = (props: IconProps) => (
  <Icon src={AVCabin.src} data-testid="AVCabinIcon" {...props} />
);
export const AccessibleLiftIcon = (props: IconProps) => (
  <Icon src={AccessibleLift.src} data-testid="AccessibleLiftIcon" {...props} />
);
export const AddIcon = (props: IconProps) => (
  <Icon src={Add.src} data-testid="AddIcon" {...props} />
);
export const AddFilledIcon = (props: IconProps) => (
  <Icon src={AddFilled.src} data-testid="AddFilledIcon" {...props} />
);
export const AddLinesIcon = (props: IconProps) => (
  <Icon src={AddLines.src} data-testid="AddLinesIcon" {...props} />
);
export const AirConditioningIcon = (props: IconProps) => (
  <Icon src={AirConditioning.src} data-testid="AirConditioningIcon" {...props} />
);
export const AirplaneIcon = (props: IconProps) => (
  <Icon src={Airplane.src} data-testid="AirplaneIcon" {...props} />
);
export const AlertIcon = (props: IconProps) => (
  <Icon src={Alert.src} data-testid="AlertIcon" {...props} />
);
export const AlertFilledIcon = (props: IconProps) => (
  <Icon src={AlertFilled.src} data-testid="AlertFilledIcon" {...props} />
);
export const AlertLinesIcon = (props: IconProps) => (
  <Icon src={AlertLines.src} data-testid="AlertLinesIcon" {...props} />
);
export const AllegroIcon = (props: IconProps) => (
  <Icon src={Allegro.src} data-testid="AllegroIcon" {...props} />
);
export const AllergyIcon = (props: IconProps) => (
  <Icon src={Allergy.src} data-testid="AllergyIcon" {...props} />
);
export const ArrowLeftIcon = (props: IconProps) => (
  <Icon src={ArrowLeft.src} data-testid="ArrowLeftIcon" {...props} />
);
export const ArrowLongLeftIcon = (props: IconProps) => (
  <Icon src={ArrowLongLeft.src} data-testid="ArrowLongLeftIcon" {...props} />
);
export const ArrowLongRightIcon = (props: IconProps) => (
  <Icon src={ArrowLongRight.src} data-testid="ArrowLongRightIcon" {...props} />
);
export const ArrowRightIcon = (props: IconProps) => (
  <Icon src={ArrowRight.src} data-testid="ArrowRightIcon" {...props} />
);
export const BabyStationIcon = (props: IconProps) => (
  <Icon src={BabyStation.src} data-testid="BabyStationIcon" {...props} />
);
export const BasicSeatIcon = (props: IconProps) => (
  <Icon src={BasicSeat.src} data-testid="BasicSeatIcon" {...props} />
);
export const BedIcon = (props: IconProps) => (
  <Icon src={Bed.src} data-testid="BedIcon" {...props} />
);
export const BikeIcon = (props: IconProps) => (
  <Icon src={Bike.src} data-testid="BikeIcon" {...props} />
);
export const BikeUnavailableIcon = (props: IconProps) => (
  <Icon src={BikeUnavailable.src} data-testid="BikeUnavailableIcon" {...props} />
);
export const BinIcon = (props: IconProps) => (
  <Icon src={Bin.src} data-testid="BinIcon" {...props} />
);
export const BunkBedIcon = (props: IconProps) => (
  <Icon src={BunkBed.src} data-testid="BunkBedIcon" {...props} />
);
export const BusIcon = (props: IconProps) => (
  <Icon src={Bus.src} data-testid="BusIcon" {...props} />
);
export const BusStationIcon = (props: IconProps) => (
  <Icon src={BusStation.src} data-testid="BusStationIcon" {...props} />
);
export const BusinessTravellerIcon = (props: IconProps) => (
  <Icon src={BusinessTraveller.src} data-testid="BusinessTravellerIcon" {...props} />
);
export const CabinIcon = (props: IconProps) => (
  <Icon src={Cabin.src} data-testid="CabinIcon" {...props} />
);
export const CabinCombinedIcon = (props: IconProps) => (
  <Icon src={CabinCombined.src} data-testid="CabinCombinedIcon" {...props} />
);
export const CabinWithWCShowerIcon = (props: IconProps) => (
  <Icon src={CabinWithWCShower.src} data-testid="CabinWithWCShowerIcon" {...props} />
);
export const CalendarIcon = (props: IconProps) => (
  <Icon src={Calendar.src} data-testid="CalendarIcon" {...props} />
);
export const CancelIcon = (props: IconProps) => (
  <Icon src={Cancel.src} data-testid="CancelIcon" {...props} />
);
export const CancelFilledIcon = (props: IconProps) => (
  <Icon src={CancelFilled.src} data-testid="CancelFilledIcon" {...props} />
);
export const CarIcon = (props: IconProps) => (
  <Icon src={Car.src} data-testid="CarIcon" {...props} />
);
export const CarTrainIcon = (props: IconProps) => (
  <Icon src={CarTrain.src} data-testid="CarTrainIcon" {...props} />
);
export const ChainIcon = (props: IconProps) => (
  <Icon src={Chain.src} data-testid="ChainIcon" {...props} />
);
export const ChangeWaitIcon = (props: IconProps) => (
  <Icon src={ChangeWait.src} data-testid="ChangeWaitIcon" {...props} />
);
export const ChangesIcon = (props: IconProps) => (
  <Icon src={Changes.src} data-testid="ChangesIcon" {...props} />
);
export const ChatIcon = (props: IconProps) => (
  <Icon src={Chat.src} data-testid="ChatIcon" {...props} />
);
export const ChevronDownIcon = (props: IconProps) => (
  <Icon src={ChevronDown.src} data-testid="ChevronDownIcon" {...props} />
);
export const ChevronLeftIcon = (props: IconProps) => (
  <Icon src={ChevronLeft.src} data-testid="ChevronLeftIcon" {...props} />
);
export const ChevronRightIcon = (props: IconProps) => (
  <Icon src={ChevronRight.src} data-testid="ChevronRightIcon" {...props} />
);
export const ChevronUpIcon = (props: IconProps) => (
  <Icon src={ChevronUp.src} data-testid="ChevronUpIcon" {...props} />
);
export const CloakroomIcon = (props: IconProps) => (
  <Icon src={Cloakroom.src} data-testid="CloakroomIcon" {...props} />
);
export const CocktailIcon = (props: IconProps) => (
  <Icon src={Cocktail.src} data-testid="CocktailIcon" {...props} />
);
export const CoffeeIcon = (props: IconProps) => (
  <Icon src={Coffee.src} data-testid="CoffeeIcon" {...props} />
);
export const CollapseIcon = (props: IconProps) => (
  <Icon src={Collapse.src} data-testid="CollapseIcon" {...props} />
);
export const ConductorIcon = (props: IconProps) => (
  <Icon src={Conductor.src} data-testid="ConductorIcon" {...props} />
);
export const ConnectedCabinIcon = (props: IconProps) => (
  <Icon src={ConnectedCabin.src} data-testid="ConnectedCabinIcon" {...props} />
);
export const CreditCardIcon = (props: IconProps) => (
  <Icon src={CreditCard.src} data-testid="CreditCardIcon" {...props} />
);
export const DetailedGlacierIcon = (props: IconProps) => (
  <Icon src={DetailedGlacier.src} data-testid="DetailedGlacierIcon" {...props} />
);
export const DetailedHamburgerIcon = (props: IconProps) => (
  <Icon src={DetailedHamburger.src} data-testid="DetailedHamburgerIcon" {...props} />
);
export const DirectIcon = (props: IconProps) => (
  <Icon src={Direct.src} data-testid="DirectIcon" {...props} />
);
export const DisabledServicesIcon = (props: IconProps) => (
  <Icon src={DisabledServices.src} data-testid="DisabledServicesIcon" {...props} />
);
export const DownloadIcon = (props: IconProps) => (
  <Icon src={Download.src} data-testid="DownloadIcon" {...props} />
);
export const DurationIcon = (props: IconProps) => (
  <Icon src={Duration.src} data-testid="DurationIcon" {...props} />
);
export const EditIcon = (props: IconProps) => (
  <Icon src={Edit.src} data-testid="EditIcon" {...props} />
);
export const EkoIcon = (props: IconProps) => (
  <Icon src={Eko.src} data-testid="EkoIcon" {...props} />
);
export const EkstraIcon = (props: IconProps) => (
  <Icon src={Ekstra.src} data-testid="EkstraIcon" {...props} />
);
export const EkstraNewEnIcon = (props: IconProps) => (
  <Icon src={EkstraNewEn.src} data-testid="EkstraNewEnIcon" {...props} />
);
export const EkstraNewFiIcon = (props: IconProps) => (
  <Icon src={EkstraNewFi.src} data-testid="EkstraNewFiIcon" {...props} />
);
export const EkstraNewSeIcon = (props: IconProps) => (
  <Icon src={EkstraNewSe.src} data-testid="EkstraNewSeIcon" {...props} />
);
export const EmailIcon = (props: IconProps) => (
  <Icon src={Email.src} data-testid="EmailIcon" {...props} />
);
export const EmotionHappyIcon = (props: IconProps) => (
  <Icon src={EmotionHappy.src} data-testid="EmotionHappyIcon" {...props} />
);
export const EmotionSadIcon = (props: IconProps) => (
  <Icon src={EmotionSad.src} data-testid="EmotionSadIcon" {...props} />
);
export const ExpandIcon = (props: IconProps) => (
  <Icon src={Expand.src} data-testid="ExpandIcon" {...props} />
);
export const ExternalLinkIcon = (props: IconProps) => (
  <Icon src={ExternalLink.src} data-testid="ExternalLinkIcon" {...props} />
);
export const FaceForwardIcon = (props: IconProps) => (
  <Icon src={FaceForward.src} data-testid="FaceForwardIcon" {...props} />
);
export const FacebookIcon = (props: IconProps) => (
  <Icon src={Facebook.src} data-testid="FacebookIcon" {...props} />
);
export const FacingSeatsIcon = (props: IconProps) => (
  <Icon src={FacingSeats.src} data-testid="FacingSeatsIcon" {...props} />
);
export const FamilyIcon = (props: IconProps) => (
  <Icon src={Family.src} data-testid="FamilyIcon" {...props} />
);
export const FerryIcon = (props: IconProps) => (
  <Icon src={Ferry.src} data-testid="FerryIcon" {...props} />
);
export const FileIcon = (props: IconProps) => (
  <Icon src={File.src} data-testid="FileIcon" {...props} />
);
export const FullScreenIcon = (props: IconProps) => (
  <Icon src={FullScreen.src} data-testid="FullScreenIcon" {...props} />
);
export const HearingAidIcon = (props: IconProps) => (
  <Icon src={HearingAid.src} data-testid="HearingAidIcon" {...props} />
);
export const HeartIcon = (props: IconProps) => (
  <Icon src={Heart.src} data-testid="HeartIcon" {...props} />
);
export const HomeIcon = (props: IconProps) => (
  <Icon src={Home.src} data-testid="HomeIcon" {...props} />
);
export const InfoCircleFilledIcon = (props: IconProps) => (
  <Icon src={InfoCircleFilled.src} data-testid="InfoCircleFilledIcon" {...props} />
);
export const InfoCircleLinesIcon = (props: IconProps) => (
  <Icon src={InfoCircleLines.src} data-testid="InfoCircleLinesIcon" {...props} />
);
export const InstagramIcon = (props: IconProps) => (
  <Icon src={Instagram.src} data-testid="InstagramIcon" {...props} />
);
export const LaptopIcon = (props: IconProps) => (
  <Icon src={Laptop.src} data-testid="LaptopIcon" {...props} />
);
export const LargeLuggageIcon = (props: IconProps) => (
  <Icon src={LargeLuggage.src} data-testid="LargeLuggageIcon" {...props} />
);
export const LocalTrafficIcon = (props: IconProps) => (
  <Icon src={LocalTraffic.src} data-testid="LocalTrafficIcon" {...props} />
);
export const LocationIcon = (props: IconProps) => (
  <Icon src={Location.src} data-testid="LocationIcon" {...props} />
);
export const LockIcon = (props: IconProps) => (
  <Icon src={Lock.src} data-testid="LockIcon" {...props} />
);
export const LuggageIcon = (props: IconProps) => (
  <Icon src={Luggage.src} data-testid="LuggageIcon" {...props} />
);
export const LuggageStorageIcon = (props: IconProps) => (
  <Icon src={LuggageStorage.src} data-testid="LuggageStorageIcon" {...props} />
);
export const ManIcon = (props: IconProps) => (
  <Icon src={Man.src} data-testid="ManIcon" {...props} />
);
export const ManWomanIcon = (props: IconProps) => (
  <Icon src={ManWoman.src} data-testid="ManWomanIcon" {...props} />
);
export const MealIcon = (props: IconProps) => (
  <Icon src={Meal.src} data-testid="MealIcon" {...props} />
);
export const MetroIcon = (props: IconProps) => (
  <Icon src={Metro.src} data-testid="MetroIcon" {...props} />
);
export const MiniBistroTrolleyIcon = (props: IconProps) => (
  <Icon src={MiniBistroTrolley.src} data-testid="MiniBistroTrolleyIcon" {...props} />
);
export const MotorBikeIcon = (props: IconProps) => (
  <Icon src={MotorBike.src} data-testid="MotorBikeIcon" {...props} />
);
export const MotorcycleIcon = (props: IconProps) => (
  <Icon src={Motorcycle.src} data-testid="MotorcycleIcon" {...props} />
);
export const NegotiationCabinIcon = (props: IconProps) => (
  <Icon src={NegotiationCabin.src} data-testid="NegotiationCabinIcon" {...props} />
);
export const NightTrainIcon = (props: IconProps) => (
  <Icon src={NightTrain.src} data-testid="NightTrainIcon" {...props} />
);
export const NoStairsIcon = (props: IconProps) => (
  <Icon src={NoStairs.src} data-testid="NoStairsIcon" {...props} />
);
export const NoWifiIcon = (props: IconProps) => (
  <Icon src={NoWifi.src} data-testid="NoWifiIcon" {...props} />
);
export const NotificationsIcon = (props: IconProps) => (
  <Icon src={Notifications.src} data-testid="NotificationsIcon" {...props} />
);
export const OfferIcon = (props: IconProps) => (
  <Icon src={Offer.src} data-testid="OfferIcon" {...props} />
);
export const OfferOutlineIcon = (props: IconProps) => (
  <Icon src={OfferOutline.src} data-testid="OfferOutlineIcon" {...props} />
);
export const ParkingIcon = (props: IconProps) => (
  <Icon src={Parking.src} data-testid="ParkingIcon" {...props} />
);
export const PasswordHideIcon = (props: IconProps) => (
  <Icon src={PasswordHide.src} data-testid="PasswordHideIcon" {...props} />
);
export const PasswordViewIcon = (props: IconProps) => (
  <Icon src={PasswordView.src} data-testid="PasswordViewIcon" {...props} />
);
export const PetIcon = (props: IconProps) => (
  <Icon src={Pet.src} data-testid="PetIcon" {...props} />
);
export const PetCabinIcon = (props: IconProps) => (
  <Icon src={PetCabin.src} data-testid="PetCabinIcon" {...props} />
);
export const PetCabinWithShowerWCIcon = (props: IconProps) => (
  <Icon src={PetCabinWithShowerWC.src} data-testid="PetCabinWithShowerWCIcon" {...props} />
);
export const PetCabinWithWCIcon = (props: IconProps) => (
  <Icon src={PetCabinWithWC.src} data-testid="PetCabinWithWCIcon" {...props} />
);
export const PetCabinWithWCShowerIcon = (props: IconProps) => (
  <Icon src={PetCabinWithWCShower.src} data-testid="PetCabinWithWCShowerIcon" {...props} />
);
export const PetUnavailableIcon = (props: IconProps) => (
  <Icon src={PetUnavailable.src} data-testid="PetUnavailableIcon" {...props} />
);
export const PhoneBoothIcon = (props: IconProps) => (
  <Icon src={PhoneBooth.src} data-testid="PhoneBoothIcon" {...props} />
);
export const PlayAreaIcon = (props: IconProps) => (
  <Icon src={PlayArea.src} data-testid="PlayAreaIcon" {...props} />
);
export const PlugIcon = (props: IconProps) => (
  <Icon src={Plug.src} data-testid="PlugIcon" {...props} />
);
export const PramIcon = (props: IconProps) => (
  <Icon src={Pram.src} data-testid="PramIcon" {...props} />
);
export const PrintIcon = (props: IconProps) => (
  <Icon src={Print.src} data-testid="PrintIcon" {...props} />
);
export const QRIcon = (props: IconProps) => <Icon src={QR.src} data-testid="QRIcon" {...props} />;
export const QuestionMarkIcon = (props: IconProps) => (
  <Icon src={QuestionMark.src} data-testid="QuestionMarkIcon" {...props} />
);
export const QuestionMarkFilledIcon = (props: IconProps) => (
  <Icon src={QuestionMarkFilled.src} data-testid="QuestionMarkFilledIcon" {...props} />
);
export const ReceiptIcon = (props: IconProps) => (
  <Icon src={Receipt.src} data-testid="ReceiptIcon" {...props} />
);
export const ReloadIcon = (props: IconProps) => (
  <Icon src={Reload.src} data-testid="ReloadIcon" {...props} />
);
export const ReloadVariantIcon = (props: IconProps) => (
  <Icon src={ReloadVariant.src} data-testid="ReloadVariantIcon" {...props} />
);
export const RemoveIcon = (props: IconProps) => (
  <Icon src={Remove.src} data-testid="RemoveIcon" {...props} />
);
export const RemoveFilledIcon = (props: IconProps) => (
  <Icon src={RemoveFilled.src} data-testid="RemoveFilledIcon" {...props} />
);
export const RemoveLinesIcon = (props: IconProps) => (
  <Icon src={RemoveLines.src} data-testid="RemoveLinesIcon" {...props} />
);
export const RestaurantIcon = (props: IconProps) => (
  <Icon src={Restaurant.src} data-testid="RestaurantIcon" {...props} />
);
export const RestaurantTrolleyIcon = (props: IconProps) => (
  <Icon src={RestaurantTrolley.src} data-testid="RestaurantTrolleyIcon" {...props} />
);
export const RestaurantUpstairsIcon = (props: IconProps) => (
  <Icon src={RestaurantUpstairs.src} data-testid="RestaurantUpstairsIcon" {...props} />
);
export const RetailIcon = (props: IconProps) => (
  <Icon src={Retail.src} data-testid="RetailIcon" {...props} />
);
export const ReturnIcon = (props: IconProps) => (
  <Icon src={Return.src} data-testid="ReturnIcon" {...props} />
);
export const ScaleIcon = (props: IconProps) => (
  <Icon src={Scale.src} data-testid="ScaleIcon" {...props} />
);
export const SearchIcon = (props: IconProps) => (
  <Icon src={Search.src} data-testid="SearchIcon" {...props} />
);
export const SendIcon = (props: IconProps) => (
  <Icon src={Send.src} data-testid="SendIcon" {...props} />
);
export const SettingsIcon = (props: IconProps) => (
  <Icon src={Settings.src} data-testid="SettingsIcon" {...props} />
);
export const ShareIcon = (props: IconProps) => (
  <Icon src={Share.src} data-testid="ShareIcon" {...props} />
);
export const ShowerIcon = (props: IconProps) => (
  <Icon src={Shower.src} data-testid="ShowerIcon" {...props} />
);
export const SilentIcon = (props: IconProps) => (
  <Icon src={Silent.src} data-testid="SilentIcon" {...props} />
);
export const SortingIcon = (props: IconProps) => (
  <Icon src={Sorting.src} data-testid="SortingIcon" {...props} />
);
export const StationIcon = (props: IconProps) => (
  <Icon src={Station.src} data-testid="StationIcon" {...props} />
);
export const SwapIcon = (props: IconProps) => (
  <Icon src={Swap.src} data-testid="SwapIcon" {...props} />
);
export const SweetIcon = (props: IconProps) => (
  <Icon src={Sweet.src} data-testid="SweetIcon" {...props} />
);
export const TVMIcon = (props: IconProps) => (
  <Icon src={TVM.src} data-testid="TVMIcon" {...props} />
);
export const TaxiIcon = (props: IconProps) => (
  <Icon src={Taxi.src} data-testid="TaxiIcon" {...props} />
);
export const TaxiStopIcon = (props: IconProps) => (
  <Icon src={TaxiStop.src} data-testid="TaxiStopIcon" {...props} />
);
export const ThumbsDownIcon = (props: IconProps) => (
  <Icon src={ThumbsDown.src} data-testid="ThumbsDownIcon" {...props} />
);
export const ThumbsUpIcon = (props: IconProps) => (
  <Icon src={ThumbsUp.src} data-testid="ThumbsUpIcon" {...props} />
);
export const TickIcon = (props: IconProps) => (
  <Icon src={Tick.src} data-testid="TickIcon" {...props} />
);
export const TickFilledIcon = (props: IconProps) => (
  <Icon src={TickFilled.src} data-testid="TickFilledIcon" {...props} />
);
export const TicketIcon = (props: IconProps) => (
  <Icon src={Ticket.src} data-testid="TicketIcon" {...props} />
);
export const TicketSeasonIcon = (props: IconProps) => (
  <Icon src={TicketSeason.src} data-testid="TicketSeasonIcon" {...props} />
);
export const TicketSerialIcon = (props: IconProps) => (
  <Icon src={TicketSerial.src} data-testid="TicketSerialIcon" {...props} />
);
export const TicketSingleIcon = (props: IconProps) => (
  <Icon src={TicketSingle.src} data-testid="TicketSingleIcon" {...props} />
);
export const TimeDetailedIcon = (props: IconProps) => (
  <Icon src={TimeDetailed.src} data-testid="TimeDetailedIcon" {...props} />
);
export const TimeSimpleIcon = (props: IconProps) => (
  <Icon src={TimeSimple.src} data-testid="TimeSimpleIcon" {...props} />
);
export const TrackIcon = (props: IconProps) => (
  <Icon src={Track.src} data-testid="TrackIcon" {...props} />
);
export const TrainCommuterIcon = (props: IconProps) => (
  <Icon src={TrainCommuter.src} data-testid="TrainCommuterIcon" {...props} />
);
export const TrainDefaultIcon = (props: IconProps) => (
  <Icon src={TrainDefault.src} data-testid="TrainDefaultIcon" {...props} />
);
export const TrainDetailedIcon = (props: IconProps) => (
  <Icon src={TrainDetailed.src} data-testid="TrainDetailedIcon" {...props} />
);
export const TrainInfoIcon = (props: IconProps) => (
  <Icon src={TrainInfo.src} data-testid="TrainInfoIcon" {...props} />
);
export const TrainInterCityIcon = (props: IconProps) => (
  <Icon src={TrainInterCity.src} data-testid="TrainInterCityIcon" {...props} />
);
export const TrainLoadLevelIcon = (props: IconProps) => (
  <Icon src={TrainLoadLevel.src} data-testid="TrainLoadLevelIcon" {...props} />
);
export const TrainLoadSpaceLimitedIcon = (props: IconProps) => (
  <Icon src={TrainLoadSpaceLimited.src} data-testid="TrainLoadSpaceLimitedIcon" {...props} />
);
export const TrainLoadSpaceModerateIcon = (props: IconProps) => (
  <Icon src={TrainLoadSpaceModerate.src} data-testid="TrainLoadSpaceModerateIcon" {...props} />
);
export const TrainLoadSpacePlentyIcon = (props: IconProps) => (
  <Icon src={TrainLoadSpacePlenty.src} data-testid="TrainLoadSpacePlentyIcon" {...props} />
);
export const TrainLongDistanceIcon = (props: IconProps) => (
  <Icon src={TrainLongDistance.src} data-testid="TrainLongDistanceIcon" {...props} />
);
export const TrainPendolinoIcon = (props: IconProps) => (
  <Icon src={TrainPendolino.src} data-testid="TrainPendolinoIcon" {...props} />
);
export const TrainPlusIcon = (props: IconProps) => (
  <Icon src={TrainPlus.src} data-testid="TrainPlusIcon" {...props} />
);
export const TrainShortDistanceIcon = (props: IconProps) => (
  <Icon src={TrainShortDistance.src} data-testid="TrainShortDistanceIcon" {...props} />
);
export const TrainStationIcon = (props: IconProps) => (
  <Icon src={TrainStation.src} data-testid="TrainStationIcon" {...props} />
);
export const TramIcon = (props: IconProps) => (
  <Icon src={Tram.src} data-testid="TramIcon" {...props} />
);
export const TravelIcon = (props: IconProps) => (
  <Icon src={Travel.src} data-testid="TravelIcon" {...props} />
);
export const TwitterIcon = (props: IconProps) => (
  <Icon src={Twitter.src} data-testid="TwitterIcon" {...props} />
);
export const TwitterXIcon = (props: IconProps) => (
  <Icon src={TwitterX.src} data-testid="TwitterXIcon" {...props} />
);
export const TypographyIcon = (props: IconProps) => (
  <Icon src={Typography.src} data-testid="TypographyIcon" {...props} />
);
export const UmbrellaIcon = (props: IconProps) => (
  <Icon src={Umbrella.src} data-testid="UmbrellaIcon" {...props} />
);
export const UserDefaultIcon = (props: IconProps) => (
  <Icon src={UserDefault.src} data-testid="UserDefaultIcon" {...props} />
);
export const UserGroupIcon = (props: IconProps) => (
  <Icon src={UserGroup.src} data-testid="UserGroupIcon" {...props} />
);
export const UserInCircleIcon = (props: IconProps) => (
  <Icon src={UserInCircle.src} data-testid="UserInCircleIcon" {...props} />
);
export const UserIndicatorFilledIcon = (props: IconProps) => (
  <Icon src={UserIndicatorFilled.src} data-testid="UserIndicatorFilledIcon" {...props} />
);
export const UserIndicatorOutlineIcon = (props: IconProps) => (
  <Icon src={UserIndicatorOutline.src} data-testid="UserIndicatorOutlineIcon" {...props} />
);
export const VRLogoIcon = (props: IconProps) => (
  <Icon src={VRLogo.src} data-testid="VRLogoIcon" {...props} />
);
export const VanIcon = (props: IconProps) => (
  <Icon src={Van.src} data-testid="VanIcon" {...props} />
);
export const WCIcon = (props: IconProps) => <Icon src={WC.src} data-testid="WCIcon" {...props} />;
export const WCAccessibleIcon = (props: IconProps) => (
  <Icon src={WCAccessible.src} data-testid="WCAccessibleIcon" {...props} />
);
export const WagonIcon = (props: IconProps) => (
  <Icon src={Wagon.src} data-testid="WagonIcon" {...props} />
);
export const WaterBottleIcon = (props: IconProps) => (
  <Icon src={WaterBottle.src} data-testid="WaterBottleIcon" {...props} />
);
export const WheelchairIcon = (props: IconProps) => (
  <Icon src={Wheelchair.src} data-testid="WheelchairIcon" {...props} />
);
export const WheelchairUnavailableIcon = (props: IconProps) => (
  <Icon src={WheelchairUnavailable.src} data-testid="WheelchairUnavailableIcon" {...props} />
);
export const WifiIcon = (props: IconProps) => (
  <Icon src={Wifi.src} data-testid="WifiIcon" {...props} />
);
export const WineIcon = (props: IconProps) => (
  <Icon src={Wine.src} data-testid="WineIcon" {...props} />
);
export const WomanIcon = (props: IconProps) => (
  <Icon src={Woman.src} data-testid="WomanIcon" {...props} />
);
export const WorkCabinIcon = (props: IconProps) => (
  <Icon src={WorkCabin.src} data-testid="WorkCabinIcon" {...props} />
);
export const YoutubeIcon = (props: IconProps) => (
  <Icon src={Youtube.src} data-testid="YoutubeIcon" {...props} />
);
